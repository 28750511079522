// ESLint reports `fill` is missing, whereas it exists on an SVGProps type
export type SVGProps = React.SVGProps<SVGSVGElement> & {
    fill?: string
    clickable?: boolean
  }
  
  export const TelegramIcon = (props: SVGProps) => (
    <svg {...props} viewBox="0 0 38 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M37.2846 0.47753C36.6382 -0.0747874 35.6229 -0.153814 34.5732 0.270845H34.5714C33.4674 0.717215 3.31921 13.747 2.09191 14.2794C1.86869 14.3575 -0.0808597 15.0905 0.119956 16.7231C0.299225 18.1951 1.8661 18.8047 2.05744 18.8751L9.72204 21.5194C10.2305 23.225 12.1051 29.5176 12.5197 30.8619C12.7782 31.7 13.1997 32.8011 13.9383 33.0278C14.5864 33.2796 15.2311 33.0495 15.6483 32.7195L20.3342 28.34L27.8989 34.2844L28.079 34.3929C28.5927 34.6222 29.0848 34.7368 29.5545 34.7368C29.9174 34.7368 30.2656 34.6682 30.5982 34.531C31.7316 34.0621 32.1849 32.9739 32.2323 32.8506L37.8828 3.25735C38.2275 1.67682 37.7483 0.872663 37.2846 0.47753ZM16.4817 22.5772L13.8961 29.5246L11.3105 20.8403L31.1335 6.07713L16.4817 22.5772Z" fill="white"/>
    </svg>
  )
